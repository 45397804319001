@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
/* variables */
:root {
  --heading-color: #444;
  --text-color: #999;
  --primary-color: #8d69f1;
  --highlight-color: #d13267;
  --bg-color: #f4f4f4;
}

/* base styles */
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
  background: #f4f4f4;
  background: var(--bg-color);
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}

/* layout */
.page-title {
  font-size: 1em;
  color: #444;
  color: var(--heading-color);
  display: inline-block;
}
.btn {
  background: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  color: #8d69f1;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1em;
  border: 1px solid #8d69f1;
  border: 1px solid var(--primary-color);
}
.btn:hover {
  color: #fff;
  background-color: #8d69f1;
  background-color: var(--primary-color);
}

/* forms */
label {
  display: block;
  margin: 24px auto;
}
label span {
  display: block;
  margin-bottom: 6px;
}
input, textarea {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}
textarea {
  min-height: 160px;
}
.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}
 *{
 box-sizing: border-box; 
}

.App {
  
  display: flex;
}
.App .container{
  flex-grow: 1;
  padding: 20px 60px;

  position: relative;
}

/* smartphone */
@media screen and (max-width:850px) {
  .App {
    flex-direction:column;
  }

  .App > .sidebar {
    width:100%;
    flex-basis: min-content;
    padding-bottom: 30px;
    min-height: auto;
  }

  .App  .sidebar-content{
    position: relative;
  }

  .App > .sidebar .links{
    margin-top:0px;
  }

  .App > .sidebar .user{
    padding: 5px 30px;
  }



  .App > .container {
    width:100%;
  }

  .App > .user-list {
    width:100%;
  }


  .App .project-filter nav {
    flex-direction: column;
  }

  .App .project-list {
    grid-template-columns: 1fr;
  }

  .App .project-details {
    grid-template-columns: 1fr;
  }
  
}

/*tablet */

@media screen and (min-width:850px) and (max-width:1170px) {

  .App {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: -webkit-min-content auto;
    grid-template-rows: min-content auto;
    min-height: 100vh;
    
  }

  .App .sidebar {
    grid-column: 1/2;
    grid-row: 1/2;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;

  }
  .App .sidebar-content {
    position:relative;
  }

  .App .user-list {
    grid-column: 1/2;
    grid-row: 2/3;
    min-width: 300px;
  }

  .App .container{
    grid-column: 2/3;
    grid-row: 1/3;
  }

}
.avatar {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.avatar img {
  width: 100%;
  height: 100%;
}
.project-list {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 20px;
}
.project-list a {
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  color: inherit;
}
.project-list h4 {
  font-size: 0.9em;
  color: var(--heading-color);
}
.project-list p {
  color: var(--text-color);
  font-size: 0.9em;
}
.project-list .assigned-to {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}
.project-list ul {
  margin: 10px 0;
  display: flex;
}
.project-list li {
  margin-right: 10px;
}
.project-list .avatar {
  width: 30px;
  height: 30px;
}
.dashboard{
  position: relative;
}

.project-filter {
  margin: 30px auto;
}
.project-filter nav {
  display: flex;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
}
.project-filter p {
  font-size: 0.9em;
  margin-right: 10px;
}
.project-filter button {
  background: transparent;
  border: 0;
  font-family: inherit;
  font-weight: bold;
  color: var(--text-color);
  cursor: pointer;
  border-right: 1px solid #e4e4e4;
  font-size: 0.9em;
}
.project-filter button:last-child {
  border: 0;
}
.project-filter button.active {
  color: var(--primary-color);
} 
.create-form {
  max-width: 600px;
}

.auth-form {
  max-width: 360px;
  margin: 60px auto;
  padding: 40px;
  border: 1px solid #ddd;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
  background: #fff;
}
.project-details {
  display: grid;
  grid-template-columns: 3fr 2fr;
  align-items: start;
  grid-gap: 60px;
}

/* project summary */
.project-summary {
  background-color: #fff;
  padding: 30px;
  border-radius: 4px;
}
.project-summary .due-date {
  margin: 10px 0;
  font-size: 0.9em;
  color: var(--title-color);
}
.project-summary .details {
  margin: 30px 0;
  color: var(--text-color);
  line-height: 1.8em;
  font-size: 0.9em;
}
.project-summary h4 {
  color: var(--text-color);
  font-size: 0.9em;
}
.project-summary .assigned-users {
  display: flex;
  margin-top: 20px;
}
.project-summary .assigned-users .avatar {
  margin-right: 10px;
}
.project-summary + .btn {
  margin-top: 20px;
}

/* project comments */
.project-comments label {
  margin-bottom: 0px;
}
.project-comments textarea {
  min-height: 40px;
  font-size: 1.5em;
}

/* comment list */
.project-comments h4 {
  color: var(--heading-color);
}
.project-comments li {
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  margin-top: 20px;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
  background: #fff;
}
.comment-author {
  display: flex;
  align-items: center;
  color: var(--title-color);
}
.comment-author .avatar {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.comment-date {
  color: var(--text-color);
  font-size: 0.9em;
  margin: 4px 0 10px;
}
.comment-content {
  color: var(--text-color);
  font-size: 0.9em;
}

/* comments scrollbar */
.project-comments {
  max-height: 800px;
  overflow: auto;
  padding-right: 20px;
  /* firefox scrollbar style */
  scrollbar-width: 20px;
  scrollbar-color: #fff #e4e4e4;
}
/* Chrome, Edge, and Safari scrollbar styles */
.project-comments::-webkit-scrollbar {
  width: 6px;
}
.project-comments::-webkit-scrollbar-track {
  background: #fff;
}
.project-comments::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 3px solid #e4e4e4;
}
.navbar {
  width: 100%;
  padding: 30px 0;
  box-sizing: border-box;
  margin-bottom: 80px;
}
.navbar ul {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: flex-end;
}
.navbar .logo {
  font-weight: bold;
  color: var(--heading-color);
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  margin-right: auto;
}
.navbar .logo img {
  margin-right: 10px;
  -webkit-filter: invert(25%);
          filter: invert(25%);
  width: 36px;
  margin-top: -8px;
}
.navbar a {
  color: #333;
  text-decoration: none;
  margin-right: 20px;
}
.sidebar {
  width: 300px;
  min-width: 300px;
  background: var(--primary-color);
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  color: #fff;
}
.sidebar-content {
  position: fixed;
  width: inherit;
}
.sidebar .user {
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  padding: 40px 30px;
  border-bottom: 1px solid rgba(255,255,255,0.2);
}
.sidebar .links {
  margin-top: 80px;
  margin-left: 20px;
}
.sidebar .links li {
  margin-top: 10px;
}
.sidebar .links a {
  display: flex;
  padding: 10px;
  text-decoration: none;
  width: 100%;
  color: #fff;
  box-sizing: border-box;
}
.sidebar .links img {
  margin-right: 10px;
  -webkit-filter: invert(100%);
          filter: invert(100%);
}
.sidebar .links a.active {
  color: #555;
  background: var(--bg-color);
  border-radius: 20px 0 0 20px;
}
.sidebar .links .active img {
  -webkit-filter: invert(40%);
          filter: invert(40%);
}

/* avatar in sidebar */
.sidebar .user .avatar {
  margin-bottom: 10px;
  width: 70px;
  height: 70px;
  border: 3px solid #fff;
}
.user-list {
  width: 250px;
  min-width: 250px;
  padding: 30px;
  box-sizing: border-box;
  background: #fbfbfb;
  color: var(--heading-color);
}
.user-list h2 {
  text-align: right;
  margin-bottom: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  font-size: 1.2em;
}
.user-list .user-list-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px auto;
  cursor:pointer;
  position: relative;
}
.user-list .avatar {
  margin-left: 10px;
  width: 40px;
  height: 40px;
}

/* online users */
.user-list .online-user {
  display: inline-block;
  margin-right: 10px;
  width: 12px;
  height: 12px;
  background: #0ebb50;
  border-radius: 50%;
  margin-top: 2px;
}

.user-list .new-message {
  position:absolute;
  top: 12px;
  right: -23px;
  width: 20px;
  -webkit-animation-name: new-message-blinking;
          animation-name: new-message-blinking;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;

}

@-webkit-keyframes new-message-blinking {
  0%    {opacity:0;}
  50%   {opacity:1;}
  100%  {opacity:0;}
}

@keyframes new-message-blinking {
  0%    {opacity:0;}
  50%   {opacity:1;}
  100%  {opacity:0;}
}
.chat-list{
    position: fixed;
    bottom:0;
    left:300px;
    display:flex;
    grid-gap:10px;
    gap:10px;
    margin:5px;
}


@media screen and (max-width:850px){
    .chat-list{
        flex-direction: column;
        left:0px;
    }
}
.chat {
    width:250px;
    
    

    background-color: white;
    border-radius: 5px;

}

.chat .label-top {
    display:flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary-color);
    padding:5px 10px;
    color:white;
    font-size:0.8em;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    

}

.chat .label-top > * {
    cursor:pointer;
}

.chat .label-top .avatar {
    width:30px;
    height: 30px;
}


.chat .content {
    height: 350px;
    overflow-y: scroll;
}

.chat .label-bottom form {
    display:flex;
    justify-content: space-between;
}

.chat .label-bottom button {
    background-color: var(--primary-color);
    color:white;
    border: none;
    cursor:pointer;
}

.chat .content .message-container {
    display:flex;
    flex-direction: column;
    
    margin-bottom: 5px;
    margin-top:5px;
}

.chat .content .message-container.outgoing{
    align-items: end;
}

.chat .content .message-container.incoming{
    align-items:start;
}

.chat .content .message-container .message{
    padding:10px;
    border-radius: 5px;
    background-color: aliceblue;
}


.chat .content .message-container.outgoing .message{
    background-color: var(--primary-color);
    color:white;
}


.chat .content .message-container .message-time {
    font-size:0.6em;
    padding:0 10px 10px;
}

.chat .content .load-more {
    text-align: center;
}
.chat .content .load-more button {
    background-color: transparent;
    border: none;
    cursor:pointer;
}

