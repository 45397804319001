.chat-list{
    position: fixed;
    bottom:0;
    left:300px;
    display:flex;
    gap:10px;
    margin:5px;
}


@media screen and (max-width:850px){
    .chat-list{
        flex-direction: column;
        left:0px;
    }
}