.chat {
    width:250px;
    
    

    background-color: white;
    border-radius: 5px;

}

.chat .label-top {
    display:flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary-color);
    padding:5px 10px;
    color:white;
    font-size:0.8em;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    

}

.chat .label-top > * {
    cursor:pointer;
}

.chat .label-top .avatar {
    width:30px;
    height: 30px;
}


.chat .content {
    height: 350px;
    overflow-y: scroll;
}

.chat .label-bottom form {
    display:flex;
    justify-content: space-between;
}

.chat .label-bottom button {
    background-color: var(--primary-color);
    color:white;
    border: none;
    cursor:pointer;
}

.chat .content .message-container {
    display:flex;
    flex-direction: column;
    
    margin-bottom: 5px;
    margin-top:5px;
}

.chat .content .message-container.outgoing{
    align-items: end;
}

.chat .content .message-container.incoming{
    align-items:start;
}

.chat .content .message-container .message{
    padding:10px;
    border-radius: 5px;
    background-color: aliceblue;
}


.chat .content .message-container.outgoing .message{
    background-color: var(--primary-color);
    color:white;
}


.chat .content .message-container .message-time {
    font-size:0.6em;
    padding:0 10px 10px;
}

.chat .content .load-more {
    text-align: center;
}
.chat .content .load-more button {
    background-color: transparent;
    border: none;
    cursor:pointer;
}
