 *{
 box-sizing: border-box; 
}

.App {
  
  display: flex;
}
.App .container{
  flex-grow: 1;
  padding: 20px 60px;

  position: relative;
}

/* smartphone */
@media screen and (max-width:850px) {
  .App {
    flex-direction:column;
  }

  .App > .sidebar {
    width:100%;
    flex-basis: min-content;
    padding-bottom: 30px;
    min-height: auto;
  }

  .App  .sidebar-content{
    position: relative;
  }

  .App > .sidebar .links{
    margin-top:0px;
  }

  .App > .sidebar .user{
    padding: 5px 30px;
  }



  .App > .container {
    width:100%;
  }

  .App > .user-list {
    width:100%;
  }


  .App .project-filter nav {
    flex-direction: column;
  }

  .App .project-list {
    grid-template-columns: 1fr;
  }

  .App .project-details {
    grid-template-columns: 1fr;
  }
  
}

/*tablet */

@media screen and (min-width:850px) and (max-width:1170px) {

  .App {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: min-content auto;
    min-height: 100vh;
    
  }

  .App .sidebar {
    grid-column: 1/2;
    grid-row: 1/2;
    min-height: fit-content;

  }
  .App .sidebar-content {
    position:relative;
  }

  .App .user-list {
    grid-column: 1/2;
    grid-row: 2/3;
    min-width: 300px;
  }

  .App .container{
    grid-column: 2/3;
    grid-row: 1/3;
  }

}